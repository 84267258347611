registerProcessor(
  'WhiteNoiseProcessor',
  class WhiteNoiseProcessor extends AudioWorkletProcessor {
    process(_inputs: Float32Array[][], [[output]]: Float32Array[][]): boolean {
      for (let i = 0; i < 128; i++) {
        output[i] = Math.random() * 2 - 1;
      }
      return true;
    }
  },
);
